import Vue from "vue";
import VueRouter from "vue-router";
import loginOrRegister from "@/layouts/LoginOrRegister.vue";
import dashboardLayout from "@/layouts/DashboardLayout.vue";
import user from "@/views/user/router";
import brand from "@/views/brand/router";
import userRegister from "@/views/user/router/register";
import brandRegister from "@/views/brand/router/register";
import store from "../store";
import { Trans } from "@/plugins/Translation";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login.chunk" */ "@/views/common/Login.vue"),
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login.chunk" */ "@/views/common/Login.vue"),
  },
  {
    path: "/:lang/user",
    component: dashboardLayout,
    beforeEnter: Trans.routeMiddleware,
    children: [...user],
  },
  {
    path: "/:lang/user/login",
    component: loginOrRegister,
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "",
        name: "userloginOrRegister",
        beforeEnter: Trans.routeMiddleware,
        component: () =>
          import(
            /* webpackChunkName: "userLogin.chunk" */ "@/views/user/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/:lang/user/register",
    component: loginOrRegister,
    beforeEnter: Trans.routeMiddleware,
    children: [...userRegister],
  },
  {
    path: "/:lang/brand",
    component: dashboardLayout,
    beforeEnter: Trans.routeMiddleware,
    children: [...brand],
  },
  {
    path: "/:lang/brand/login",
    component: loginOrRegister,
    children: [
      {
        path: "",
        name: "brandloginOrRegister",
        beforeEnter: Trans.routeMiddleware,
        component: () =>
          import(
            /* webpackChunkName: "brandLogin.chunk" */ "@/views/brand/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/:lang/brand/register",
    component: loginOrRegister,
    beforeEnter: Trans.routeMiddleware,
    children: [...brandRegister],
  },
  {
    // Redirect user to supported lang version.
    path: "*",
    redirect(to) {
    return Trans.getUserSupportedLang() + "/user/login";
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  const isLoggedIn = store.getters.isAuthenticated;
  const userType = store.getters.user.type;
  const module = to.fullPath.split("/")[2];
  const currentLang = Trans.currentLanguage;
  
  if (to.fullPath == "/") {
    if (isLoggedIn) {
      let startupUrl = `${currentLang}/user`; /*"/user/"*/;
      if (userType == 1) {
        startupUrl = `${currentLang}/brand`; /*"//brand/";*/
      }
      next({
        path: startupUrl,
      });
    } else {
      window.location.href = process.env.VUE_APP_FRONT_URL;
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!isLoggedIn) {
        store.dispatch("logOut");
        console.log(to.fullPath)
        next({
          path: `/${currentLang}/` + module + "/login",
          query: { redirect: to.fullPath },
        });
        store.dispatch("alert/error", "Please login to continue.");
      } else if (!userType == to.meta.userType) {
        let dashboardurl = `${currentLang}/user`; /*"/en/user/";*/
        if (userType == 1) {
          dashboardurl = `${currentLang}/brand`; /*"/en/brand/";*/
        }
        next({
          path: dashboardurl,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
