export default [
  {
    path: "/",
    name: "brandDashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/brand/Dashboard.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "new-campaign",
    name: "brandNewCampaign",
    component: () =>
      import(
        /* webpackChunkName: "newCampaign" */
        "@/views/brand/NewCampaign.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "influencers",
    name: "brandInfluencers",
    component: () =>
      import(
        /* webpackChunkName: "influencers" */
        "@/views/brand/Influencers.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "influencer/:id/:name",
    name: "brandInfluencersDetail",
    component: () =>
      import(
        /* webpackChunkName: "influencerDetail" */
        "@/views/brand/InfluencerDetail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    // path: "campaign-detail/",
    path: "my-campaign/:id/:name",
    name: "brandCampaign",
    component: () =>
      import(
        /* webpackChunkName: "campaignDetail" */
        "@/views/brand/MyCampaign.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "deals",
    name: "brandDeals",
    component: () =>
      import(/* webpackChunkName: "deals" */ "@/views/brand/Deals.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "deal/:id/:name",
    name: "brandDeal",
    component: () =>
      import(
        /* webpackChunkName: "Deal" */
        "@/views/brand/DealDetail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "billing",
    name: "brandBillingHistory",
    component: () =>
      import(/* webpackChunkName: "deals" */ "@/views/brand/Billing.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "edit-profile",
    name: "brandEditProfile",
    component: () =>
      import(
        /* webpackChunkName: "editProfile" */
        "@/views/brand/EditProfile.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "notifications",
    name: "brandNotifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */
        "@/views/brand/Notifications.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "verify",
    name: "brandVerify",
    component: () =>
      import(
        /* webpackChunkName: "verifyemail" */
        "@/views/brand/VerifyEmail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "403",
    name: "brand-403",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/403.vue"),
  },
  {
    path: "404",
    name: "brand-404",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/404.vue"),
  },
  {
    path: "tools",
    name: "brandTools",
    component: () =>
      import(/* webpackChunkName: "tools" */ "@/views/common/Tools.vue"),
  },
];
