import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation createSession($email: String!, $password: String!) {
    createSession(email: $email, password: $password) {
      token
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($email: String!, $password: String!, $type: Int!) {
    registerUser(email: $email, password: $password, type: $type) {
      token
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $birthdate: String
    $address: String
    $idLocation: Int
    $idCurrency: Int
    $phone: String
    $gender: String
    $login_type: String
    $login_user_data: String
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      birthdate: $birthdate
      address: $address
      idLocation: $idLocation
      idCurrency: $idCurrency
      phone: $phone
      gender: $gender
      login_type: $login_type
      login_user_data: $login_user_data
    ) {
      state
    }
  }
`;
export const UPLOAD_IMAGE = gql`
  mutation uploadProfile($image: Upload) {
    uploadProfile(image: $image) {
      value
    }
  }
`;
export const RESEND_PIN = gql`
  mutation resendPin {
    resendPin {
      state
      msg
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String, $retype: String) {
    updatePassword(password: $password, retype: $retype) {
      state
      msg
    }
  }
`;
export const REGISTER_USER_WITH_SOCIAL_ACCOUNT = gql`
  mutation registerUserWithSocialAccount(
    $social_access_token: String!
    $social_login_id: String!
    $social_login_platform: String
    $social_login_user_type: Int!
  ) {
    registerUserWithSocialAccount(
      social_access_token: $social_access_token
      social_login_id: $social_login_id
      social_login_platform: $social_login_platform
      social_login_user_type: $social_login_user_type
    ) {
      token
    }
  }
`;
export const ADMIN_LOGIN_USER = gql`
  mutation createLoginSession($email: String!) {
    createLoginSession(email: $email) {
      token
    }
  }
`;
