export default [
  {
    path: "/",
    name: "userDashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/user/Dashboard.vue"),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "offers",
    name: "userOffers",
    component: () =>
      import(/* webpackChunkName: "offers" */ "@/views/user/Offers.vue"),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "discover",
    name: "userCampaign",
    component: () =>
      import(/* webpackChunkName: "discover" */ "@/views/user/Campaign.vue"),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "campaign/:id/:name",
    name: "userCampaignDetail",
    component: () =>
      import(
        /* webpackChunkName: "campaign" */
        "@/views/user/CampaignDetails.vue"
      ),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "discover-detail-deleivery",
    name: "userDiscoverDetailDeleivery",
    component: () =>
      import(
        /* webpackChunkName: "discoverDetails" */
        "@/views/user/DiscoverDetailDeleivery.vue"
      ),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "subscription/:id/:name",
    name: "userSubscription",
    component: () =>
      import(
        /* webpackChunkName: "subscription" */
        "@/views/user/Subscription.vue"
      ),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "notifications",
    name: "userNotifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */
        "@/views/user/Notifications.vue"
      ),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "edit-profile/:id?",
    name: "userEditProfile",
    component: () =>
      import(
        /* webpackChunkName: "editProfile" */
        "@/views/user/EditProfile.vue"
      ),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "offer/:id/:name",
    name: "userOfferDetail",
    component: () =>
      import(/* webpackChunkName: "offer" */ "@/views/user/OfferDetail.vue"),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "earning",
    name: "userEarning",
    component: () =>
      import(/* webpackChunkName: "offer" */ "@/views/user/Earning.vue"),
    meta: { requiresAuth: true, userType: "0" },
  },
  {
    path: "403",
    name: "user-403",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/403.vue"),
  },
  {
    path: "404",
    name: "user-404",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/404.vue"),
  },
  { // HR
    path: "tools",
    name: "UserTools",
    component: () => import(/* webpackChunkName: "tools" */ "@/views/common/Tools.vue"),
  },
];
