<template>
  <div class="locationFilter">
    <validation-provider
      :name="name"
      mode="eager"
      :rules="rules"
      v-slot="{ valid, errors }"
    >
      <b-form-group :label="label" :class="errors ? 'is-invalid' : ''">
        <!-- Dropdown -->
        <base-multi-select
          :list="locations"
          label="country"
          track-by="id"
          @input="input"
          :value="value"
          :state="errors[0] ? false : valid ? true : null"
          place-holder="Select Country"
          :error-class="{
            'invalid-selection': errors[0],
          }"
        ></base-multi-select>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    label: String,
    name: String,
    rules: String,
    value: {
      type: [Object, Array, String],
      default: null,
    },
    selected: null,
    locations: [Object, Array, String],
  },
  computed: {
    ...mapGetters({
      mediaAccounts: "mediaAccounts/list",
    }),
  },
  methods: {
    input(data) {
      this.$emit("input", data);
    },
  },
};
</script>
<style scoped lang="scss">
.locationFilter {
  ::v-deep {
    .multiselect {
      &.invalid-selection {
        border-color: red !important;
      }
    }
    .is-invalid {
      .invalid-feedback {
        display: block;
      }
    }
  }
}
</style>
