import gql from "graphql-tag";

export const LOGGED_IN_USER = gql`
  query {
    me {
      id
      email
      state
      type
      avatar
      first_name
      last_name
      birthdate
      address
      pin
      gender
      phone
      responseRate
      jobOnTime
      completion
      showTour
      location {
        id
        city
        country
        state
      }
      uTags{
        id
        tags
      }
      userAccountState
      followers
      login_type
      login_user_id
      login_user_data
      displayTools
      toolsDisplayName
    }
  }
`;

export const CHECK_USERNAME = gql`
  query usernameAvailable($email: String) {
    usernameAvailable(email: $email) {
      state
      msg
    }
  }
`;
export const AVAILABLE_MEDIA_ACCOUNTS = gql`
  query availableMediaAccounts {
    availableMediaAccounts {
      value: id
      text: name
      logo
      oAuthLink
    }
  }
`;
export const LOCATIONS = gql`
  query locations(
    $search: String
    $name: String
  ) {
    locations(
      search: $search
      name: $name
    ) {
      id
      city
      state
      country
    }
  }
`;
export const CATEGORIES = gql`
  query categories($search: String) {
    categories(search: $search) {
      id
      name
    }
  }
`;
export const BALANCE = gql`
  query {
    getBalance {
      amount
      currency
    }
  }
`;
export const CHECK_SOCIAL_ACCOUNT = gql`
  query socialAccountAvailable($socialUserID: String) {
    socialAccountAvailable(socialUserID: $socialUserID) {
      state
      msg
    }
  }
`;
export const GET_ALL_ACCOUNTS = gql`
query{
  getAllAccounts {
    id
    email
    state
    type
    avatar
    first_name
    last_name
    birthdate
    address
    pin
    gender
    phone
    responseRate
    jobOnTime
    completion
    showTour
    location {
      id
      city
      country
      state
    }
    uTags{
      id
      tags
    }
    userAccountState
    followers
    login_type
    login_user_id
    login_user_data
    displayTools
    toolsDisplayName
  }
    
}
`;
export const USERS_STATS = gql`
  query usersStats(
    $startDay: String
    $endDay: String
    $location_id: String
  ) {
    usersStats(
    startDay: $startDay
    endDay: $endDay 
    location_id: $location_id
  ) {
      totalUserStats{ 
        date
        accountCount
        instargramCount
      }
    }
  }
`;
export const LANGUAGES = gql`
  query languages(
    $id_lang: Int
  ) {
    languages(
      id_lang: $id_lang
    ) {
      id
      name
      iso_code
    }
  }
`;
