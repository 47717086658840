import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    timeStampToDate(timestamp) {
      const sec = parseInt(timestamp, 10);
      let month = Math.floor((sec % 31536000) / 2628000);
      let d = Math.floor(((sec % 31536000) % 2628000) / 86400);
      let h = Math.floor((((sec % 31536000) % 2628000) % 86400) / 3600);
      let m = Math.floor((((sec % 31536000) % 86400) % 3600) / 60);

      let monthDisplay =
        month > 0 ? month + (month == 1 ? " month, " : " months, ") : "";
      let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";

      return monthDisplay + dDisplay + hDisplay + mDisplay;
    },
    timeElapsed(timestamp) {
      const sec = parseInt(timestamp, 10);
      let month = Math.floor((sec % 31536000) / 2628000);
      let d = Math.floor(((sec % 31536000) % 2628000) / 86400);
      let h = Math.floor((((sec % 31536000) % 2628000) % 86400) / 3600);
      let m = Math.floor((((sec % 31536000) % 86400) % 3600) / 60);

      let monthDisplay =
        month > 0 ? month + (month == 1 ? " month " : " months ") : "";
      let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";

      if (monthDisplay) {
        return `${monthDisplay} Ago`;
      } else if (dDisplay) {
        return `${dDisplay} Ago`;
      } else if (hDisplay) {
        return `${hDisplay} Ago`;
      } else if (mDisplay) {
        return `${mDisplay} Ago`;
      } else {
        return `Seconds Ago`;
      }
    },
    numberToDate: function (number) {
      if (number !== null) {
        let date = new Date(Number(number)).toDateString().slice(4);
        return date;
      }
    },
    notificationTime(timestamp) {
      
      const date1 = Date.now();
      const date2 = new Date(parseInt(timestamp,10));
      
      const res = Math.abs(date1 - date2) / 1000;
     
      // get total months between two dates
      const month = Math.floor((res % 31536000) / 2628000);

      // get total days between two dates
      const d = Math.floor(res / 86400);
                             
      // get hours        
      const h = Math.floor(res / 3600) % 24;        
       
      // get minutes
      const m = Math.floor(res / 60) % 60;
       
      let monthDisplay =
        month > 0 ? month + (month == 1 ? " month " : " months ") : "";
      let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";

      if (monthDisplay) {
        return `${monthDisplay} Ago`;
      } else if (dDisplay) {
        return `${dDisplay} Ago`;
      } else if (hDisplay) {
        return `${hDisplay} Ago`;
      } else if (mDisplay) {
        return `${mDisplay} Ago`;
      } else {
        return `Seconds Ago`;
      }
           
    
    },
    friendURL(title) {
      let encodedUrl = title.toString().toLowerCase();

      encodedUrl = encodedUrl.split(/&/).join("-and-");

      encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

      encodedUrl = encodedUrl.split(/-+/).join("-");

      encodedUrl = encodedUrl.trim("-");

      return encodedUrl;
    },
    upload: function (e) {
      let wrapper = e.target.closest(".form-group");
      wrapper.classList.add("image-selected");
      let img = wrapper.querySelector("img");
      if (img) {
        img.src = URL.createObjectURL(e.target.files[0]);
      } else {
        img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        wrapper.appendChild(img);
      }
      this.$emit("input", e.target.files[0]);
    },
    formatPrice: function (
      price,
      accountCurrency = true,
      currencySymbol = true
    ) {
      let currency = accountCurrency ? this.user.currency : "$";

      if (price || price == 0) {
        price = Number(price);
        /*return currency != null && currencySymbol
          ? "<i>" + currency + "</i> " + price.toFixed(2)
          : price.toFixed(2);*/
        return currency != null && currencySymbol
          ? currency + " " + price.toFixed(2)
          : price.toFixed(2);
      }
    },
    getErrorMessage(e) {
      let error = e.message;
      console.log(e.networkError.result);
      if (typeof(e.networkError.result) != 'undefined' && e.networkError) {
        if (e.networkError.result.errors) {
          error = e.networkError.result.errors[0].message;
        }
      }
      return error;
    },
  },
};
