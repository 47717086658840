import gql from "graphql-tag";
export const CORE_INFLUENCER_FIELDS = gql`
  fragment CoreInfluencerFields on Influencer {
    id
    name
    price
    avatar
    description
    location {
      id
      city
      state
    }
    tags {
      name
    }
  }
`;
export const CORE_MEDIA_ACCOUNTS_FIELDS = gql`
  fragment CoreMediaAccountsFields on MediaAccount {
    id
    name
    logo
    followers
    username
    price
  }
`;
export const CORE_SUBSCRIBERS_FIELDS = gql`
  fragment Subscriber on ObjectSubscriber {
    id
    name
    price
    avatar
    state
    followers
    id_user
    csImages {
      srcs
    }
  }
`;
export const CORE_CAMPAIGN_FIELDS = gql`
  fragment CoreCampaignFields on Campaign {
    id
    state
    name
    logo
    company
    budget
    price
    description
    image
    media_tags
    media_handle
    posts
    is_html_description
  }
`;
export const CAMPAIGN_DETAILS_FIELDS = gql`
  fragment CampaignDetailsFields on CampaignDetails {
    title
    subtitle
    description
    gallery
  }
`;
export const CAMPAIGN_SUBSCRIPTION_FIELDS = gql`
  fragment CampaignSubscriptionFields on CampaignSubscription {
    state
    timestamp
    images {
      id
      value
    }
  }
`;
export const CAMPAIGN_MEDIA_ACCOUNT_FIELDS = gql`
  fragment CampaignMediaAccountFields on ObjectMediaAccount {
    id
    name
    logo
  }
`;
export const CORE_OFFER_FIELDS = gql`
  fragment CoreOfferFields on Offer {
    id
    name
    image
    type
    logo
    left
    description
    expirationDate
  }
`;
export const CORE_LOCATIONS_FIELDS = gql`
  fragment CoreLocationFields on ObjectLocation {
    id
    city
  }
`;
export const CORE_TAGS = gql`
  fragment CoreTags on ObjectValue {
    id
    value
  }
`;
